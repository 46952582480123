import { useRef } from "react";

const useOnce = (callback: () => void): (() => void) => {
  const hasRunRef = useRef(false);

  return () => {
    if (!hasRunRef.current) {
      callback();
      hasRunRef.current = true;
    }
  };
};

export default useOnce;
